import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((moduleRef) => {
		AppModule.injector = moduleRef.injector;
	})
	.catch(err => console.log(err));
