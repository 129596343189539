import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Global } from '../../../core/util/Global';

@Component({
  selector: 'app-headertitle',
  template: `
    <div class="title d-none">{{ title }}</div>
    <div class="type d-none">{{ type }}</div>
  `,
  styles: []
})
export class HeadertitleComponent implements OnChanges {
  @Input() title: string;
  @Input() type: string;
  @Output() public value = new EventEmitter();

  constructor(
    private global: Global
  ) { }

  ngOnChanges() {
    this.value.emit([{type: this.type}, {title: this.title}]);
  }

}
