import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './components/page/home/home.component';
import {LayoutComponent} from './core/layout/layout.component';

const authRoutes: Routes = [
	{
		path: '',
		children: [
			{path: '', component: HomepageComponent}
		],

	},
	{path: '**', redirectTo: ''}
];

const mainRoutes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: authRoutes,
	}
];

@NgModule({
	imports: [RouterModule.forRoot(mainRoutes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
