import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { LocalStorageService } from "ngx-webstorage";
import { Global } from '../util/Global';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit{
	title: string;

	constructor(
		public router: Router,
		public global: Global,
		private keepalive: Keepalive,
		private localstorage: LocalStorageService,
	) { }

	ngOnInit() {
		this.global.currentTitle.subscribe(message => this.title = message);
		if(this.global.userData !== undefined) {
			this.global.userID = this.localstorage.retrieve('userID');
			this.global.userData = JSON.parse(this.localstorage.retrieve('userData'));
		}
	}
}
