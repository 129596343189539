import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class Global {
	userID: any;
	token: any;
	userData: any = {};
	masterData: any = {};
	isShadowHeader: boolean = false;
	private titleSource = new BehaviorSubject<string>('');
	currentTitle = this.titleSource.asObservable();

	constructor(
		private localstorage: LocalStorageService
	) {
			this.userID = this.localstorage.retrieve('userID');
			this.token = this.localstorage.retrieve('access_token');
			this.userData = JSON.parse(this.localstorage.retrieve('userData'));
	  }
	  
	changeTitle(message: string) {
		setTimeout(() => this.titleSource.next(message));
	}
}
