import { Component, HostListener, } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../util/Global';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['header.component.scss']
})

export class HeaderComponent{

	constructor(
        public global : Global,
        private router: Router
	) { }

}
