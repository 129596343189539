import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[OnlyNumber]'
})
export class OnlyNumberDirective implements OnInit{

	constructor(private el: ElementRef) { }

	private navigationKeys: any = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste'
	];

	@Input() OnlyNumber: boolean;
	@Input() OnlyNumberAdditionalKeys: any = [];

	ngOnInit(){
		if(this.OnlyNumberAdditionalKeys.length > 0){
			this.navigationKeys = [...this.navigationKeys, ...this.OnlyNumberAdditionalKeys];
		}
	}

	@HostListener('keydown', ['$event']) onKeyDown(event) {
		let e = <KeyboardEvent> event;
		if (this.OnlyNumber) {
			if (
				this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
				(e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
				(e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
				(e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
				(e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
				(e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
				(e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
				(e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
				(e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
			) {
				// let it happen, don't do anything
				return;
			}
			// Ensure that it is a number and stop the keypress
			if (e.key === ' ' || isNaN(Number(e.key))) {
				e.preventDefault();
			}
		}
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(e: KeyboardEvent) {
		if (this.OnlyNumber) {
			return;
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		if (this.OnlyNumber) {
			const pastedInput: string = event.clipboardData.getData('text/plain');
			let pastedPattern;
			const defaultPattern = '[^0-9';

			if(this.OnlyNumberAdditionalKeys.length > 0){
				const additionalPattern = this.OnlyNumberAdditionalKeys.join('/');
				pastedPattern = new RegExp(defaultPattern+'/'+additionalPattern+']', 'g');
			} else{
				pastedPattern = new RegExp(defaultPattern+']', 'g');
			}

			let pasted = false;
			pasted = document.execCommand(
				'insertText',
				false,
				pastedInput.replace(pastedPattern, '')
			);

			if (pasted) {
				event.preventDefault();
			} else {
				if (navigator.clipboard) {
					navigator.clipboard.writeText(pastedInput);
					document.execCommand('paste');
				}
			}
		}
	}
}