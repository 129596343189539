

export const Option = {
    vouchers: [
        {
            image: 'assets/images/vouchers/google play.png',
            name: 'Google Play Voucher',
            key: 'google-play-voucher',
            subType: 'google_play'
        },
        {
            image: 'assets/images/vouchers/garena.png',
            name: 'Garena',
            key: 'garena',
            subType: 'garena'
        },
        {
            image: 'assets/images/vouchers/codm.png',
            name: 'Call of Duty Mobile',
            key: 'call-of-duty-mobile',
            subType: 'Topup Call of Duty Mobile'
        },
        {
            image: 'assets/images/vouchers/bleach.png',
            name: 'Bleach Mobile 3D',
            key: 'bleach-mobile-3d',
            subType: 'Bleach_3D'
        },
        {
            image: 'assets/images/vouchers/ml.png',
            name: 'Topup Mobile Legend',
            key: 'topup-mobile-legend',
            subType: 'topup_mobile_legend_super'
        },
    ]
}

