import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { ReplaceUnderscorePipe } from './app.component';
import { AppRoutingModule } from './app.routing';
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShareModule } from '@ngx-share/core';
import { TreeModule } from 'primeng/tree';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { ConfigService } from './core/config/Config';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AuthenticatedGuard } from './core/guard/AuthenticatedGuard';
import { HelperService } from './core/helper/helper.service';
import { FooterComponent } from './core/layout/footer.component';
import { HeaderComponent } from './core/layout/header.component';
import { LayoutComponent } from './core/layout/layout.component';
import { AngularMaterialModule } from './core/module.angular-material';
import { InitialPipe } from './core/pipe/initial.pipe';
import { KeysPipe } from './core/pipe/keys.pipe';
import { ReadablePipe } from './core/pipe/readable.pipe';
import { SelectorPipe } from './core/pipe/selector.pipe';
import { Constant } from './core/util/Constant';
import { Global } from './core/util/Global';
import { ModalNotificationComponent } from './core/modal/modal-notification.component';
import { tokenGetter } from './core/util/Token';
import { OnlyNumberDirective } from './core/directive/NumberOnly.directive';
import { SafePipe } from './safe.pipe';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
registerLocaleData(localeId, 'id');

import { setAppInjector } from './wavecore/component/injector/AppModuleInjector';
import { HeadertitleComponent } from './wavecore/component/headertitle/headertitle.component';
import { HomepageComponent } from './components/page/home/home.component';

import { SharedService } from './core/service/SharedService';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,

		// Directive
		OnlyNumberDirective,

		// Pipe
		ReplaceUnderscorePipe,
		SelectorPipe,
		ReadablePipe,
		KeysPipe,
		InitialPipe,

		// Modal
		ModalNotificationComponent,

		// Part Component
		HeadertitleComponent,

		// Auth Component
		LayoutComponent,
		HeaderComponent,
		FooterComponent,

		// Page Component
        HomepageComponent,

		// Page Quiz

		SafePipe,
	],
	entryComponents: [ModalNotificationComponent],
	imports: [
		FormsModule,
		FontAwesomeModule,
		HttpClientModule,
		ReactiveFormsModule,
		ShareModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		AngularMaterialModule,
		NgxUsefulSwiperModule,
		NgbModule,
		NgIdleKeepaliveModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		JwtModule.forRoot({
			config: {
				tokenGetter,
				whitelistedDomains: ['localhost:4200'],
				blacklistedRoutes: []
			}
		}),
		NgxWebstorageModule.forRoot(),
		CollapseModule.forRoot(),
		TabsModule.forRoot(),
		PaginationModule.forRoot(),
		AccordionModule.forRoot(),
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		TreeModule,
		EditorModule,
		CalendarModule,
		ProgressSpinnerModule
	],
	providers: [
		DatePipe,
		Constant,
		Global,
		AuthenticatedGuard,
		ConfigService,
		SharedService,
		HelperService,
		{provide: 'googleTagManagerId', useValue: ''},
		{provide: LOCALE_ID, useValue: 'id-ID'},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	static injector: Injector;

	constructor(injector: Injector) {
		setAppInjector(injector);
	}
}
