import {Injector} from '@angular/core';

export let AppInjector: Injector;

export function setAppInjector(injector: Injector) {
	if (this.AppInjector) {
		console.error('Programming error: AppInjector was already set');
	}
	else {
		this.AppInjector = injector;
	}
}