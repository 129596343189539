
/* tslint:disable */
/**
 * This is generated by WaveCMSCore - do not edit directly!
 */

import {Injectable} from "@angular/core";
import {BaseApiService} from '../../BaseApiService';
import { UnauthorizedRedirectTo } from '../../../../wavecore/decorator/UnauthorizedRedirect';

@Injectable({
	providedIn: 'root',
})

export class Product extends BaseApiService{
	async test(type: string): Promise<any> {
		return await this.get(`/product/test/${type}`);
	}

	async sync(): Promise<any> {
		return await this.get('/product/sync');
	}
	async getProducts(productType: string, productSubType: string): Promise<any> {
		return await this.get('/product/list/'+productType+'/'+productSubType);
	}
}
