import {Injectable} from '@angular/core';
import moment from 'moment';
import * as Env from '../../core/config/Env.json';
import {Constant} from '../util/Constant';
import {Global} from '../util/Global';

@Injectable()
export class HelperService {
	DECIMAL_SEPARATOR = ',';
	GROUP_SEPARATOR = '.';

	constructor (
		public constant: Constant, 
		public global: Global) {}

  checkFieldDepedencies(value, depedenciesName) {
		let isExist;

		const depedencies = Object.keys(this.constant[depedenciesName]);

		isExist = depedencies.find(item => item.toLowerCase() === value.toLowerCase());

		if (isExist === undefined) { return false; }

		return true;
	}

  isNumber(val) {
		const number = parseInt(val);

		if (isNaN(number)) { return false; }

		return true;
  }

  isValidDate(stringDate) {
		const valid = moment(stringDate, 'dd/MM/yyyy').isValid();

		return valid;
  }

	validateIsEmail(email: string) {
		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase());
	}

	validateStringNotEmpty(word) {
		if(!word || word === undefined || word === '') { return false; }
		return true;
	}

	getImageUrlSize(filename, size) {
		if (!this.validateStringNotEmpty(filename)) {
			this.getImageDefault();
		} else {
			return Env.baseUrl + '/assets/upload/' + size + '/' + filename;
		}
	}

	getFile(filename) {
		if (!this.validateStringNotEmpty(filename)) {
			this.getImageDefault();
		} else {
			return Env.baseUrl + 'assets/upload/file/' + filename;
		}
	}

	getImageDefault() {
		return Env.baseUrl + 'assets/images/no-photo.png';
	}

	formatNumber(valString){
		if(valString === 0) { return 0 }
		if (!valString) { return ''; }
		const val = valString.toString();
		const parts = this.unFormatNumber(val).split(this.DECIMAL_SEPARATOR);
		return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR);
	}

	unFormatNumber(val){
		if (!val) {
			return '';
		}
		val = val.replace(/^0+/, '');

		if (this.GROUP_SEPARATOR === ',') {
			console.log(val.replace(/,/g, ''));
			return val.replace(/,/g, '');
		} else {
			return val.replace(/\./g, '');
		}
	}

	scrollToTop(){
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	indoDate(date, withTime=false){
		const getDate = moment(date).locale('id').format('dddd, DD MMMM YYYY')
        return getDate
	}

	getTimeLimit(limitDate, durationHours){
		const limit = moment(limitDate).add(durationHours, 'hours')
		const now = moment()
		const isExpired = moment(now).isAfter(limit);
		const duration = moment.duration(moment(limit).diff(now));
		const diffMilliSecs = duration.asMilliseconds();
		return {diffMilliSecs, isExpired}
	}

	getTimeHours(time){
		const hours = Math.floor((time / (1000 * 60 * 60)));
		const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((time % (1000 * 60)) / 1000);
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
	}

	validateDefaultData(data){
  	const err = [];

		data.forEach(item => {
			item['fieldType'].forEach(itemType => {
				const getValidate = this.validateByField(item['fieldValue'], item['fieldLabel'], itemType);

				if(!getValidate['status']){
					err.push(getValidate['message']);
				}
			});
		});

		return err;
	}

	validateByField(data, fieldName, fieldType){
  	const result = {
  		'status' : true,
  		'message' : '',
		};

		if(fieldType === 'required'){
			if (!this.validateStringNotEmpty(data)){
				result['status'] = false;
				result['message'] = 'Field '+fieldName+' is required.';
			}
		} else if(fieldType === 'email'){
			if (!this.validateIsEmail(data)){
				result['status'] = false;
				result['message'] = fieldName+' have wrong email format.';
			}
		} else if(fieldType === 'number'){
			if (!data.toString().match(/^[0-9]+(\.?[0-9]+)?$/)){
				result['status'] = false;
				result['message'] = fieldName+' must be number only.';
			}
		}

		return result;
	}

	isCustomer(){
  	if(this.global.token === null || this.global.userData === undefined){
			return false;
		}

		return true;
	}

	truncate(str, n = 85){
		return (str.length > n) ? str.substr(0, n-1) + '...' : str;
	}
}
