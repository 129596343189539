import { AppInjector } from '../component/injector/AppModuleInjector';

export function WaveInjectable() {
	return (target) => {
		const original = target;

		const newConstructor: any = function newCtor(...args) {
			const c: any = function childConstuctor() {
				return original.apply(this, arguments);
			};
			c.prototype = Object.create(original.prototype);
			const instance = new c(...args);

			this['_toInjectList'].forEach(toInject => {
				this[toInject.name] = AppInjector.get( toInject.class );
				instance[toInject.name] = AppInjector.get( toInject.class );
			});

			return instance;
		};

		newConstructor.prototype = Object.create(target.prototype);
		return newConstructor;
	}
}