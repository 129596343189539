import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {WaveInjectable} from '../../../wavecore/decorator/Injectable';
import {WaveInject} from '../../../wavecore/decorator/InjectParam';
import {HelperService} from '../../../core/helper/helper.service';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {Payment} from "../../../core/service/api/master/Payment";
import {Product} from "../../../core/service/api/master/Product";
import {Option} from "../../../option";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})

@WaveInjectable()
export class HomepageComponent implements OnInit {
	@WaveInject paymentService: Payment;
	@WaveInject productService: Product;
	infoMessage: any = {};
	meta = {
		title: 'Plaza Topup',
		description: 'Lorem ipsum'
	}
	error = '';
	forms = {
		register: null as FormGroup,
	};
	content = {
		banner: {
			heading: {
				title: 'TUMBUH BERSAMA<br><span>PLAZA TOPUP</span>',
				description: 'Kembangkan bisnis Anda bersama Plaza Topup, solusi lengkap dalam penjualan voucher game\n',
				link: ''
			},
			image: {
				desktop: {
					src: '/assets/images/banner.png',
					alt: 'Hobitopup'
				},
				mobile: {
					src: '/assets/images/banner-mobile.png',
					alt: 'Hobitopup'
				}
			},
			description: 'Di Plaza Topup, kami menyediakan platform bagi Anda yang ingin berjualan voucher game dengan mudah.<br><br>Dengan bergabung bersama Plaza Topup, Anda akan mendapatkan keuntungan yang luar biasa dengan kemudahan yang ditawarkan bagi Anda sebagai agent kami.'
		},
		bergabung: {
			heading: {
				title: 'Mengapa harus bergabung dengan<br>PLAZA TOPUP?',
				description: ''
			},
			thumbnail: [
				{
					text: {
						title: 'Pendaftaran Gratis, Cepat, dan Mudah',
						description: '<p>Pendaftaran langsung diproses dan seluruhnya 100% gratis. Anda bisa langsung berjualan setelah proses pendaftaran selesai.</p>'
					},
					image: {
						src: '/assets/images/pendaftaran-gratis-cepat-mudah.png',
						alt: 'Pendaftaran Gratis, Cepat, dan Mudah'
					}
				},
				{
					text: {
						title: 'Pilihan Produk yang Lengkap',
						description: '<p>Tidak hanya voucher game, kami juga menyediakan voucher Google Play, Spotify, Amazon, dan berbagai macam voucher dan gift card lainnya.</p>'
					},
					image: {
						src: '/assets/images/pilihan-produk-lengkap.png',
						alt: 'Pilihan Produk yang Lengkap'
					}
				},
				{
					text: {
						title: 'Tersedia Pembayaran Segala Cara',
						description: '<p>Kami bekerja sama dengan berbagai platform yang memungkinkan customer melakukan pembayaran via e-money, pulsa, maupun transfer bank.</p>'
					},
					image: {
						src: '/assets/images/tersedia-pembayaran-segala-cara.png',
						alt: 'Tersedia Pembayaran Segala Cara'
					}
				},
				{
					text: {
						title: 'Layanan Tersedia 24 jam',
						description: '<p>Pembelian dan pembayaran bisa dilakukan selama 24 jam sehari, 7 hari seminggu, dan pembelian akan diproses langsung.</p>'
					},
					image: {
						src: '/assets/images/layanan-tersedia-24-jam.png',
						alt: 'Layanan Tersedia 24 jam'
					}
				},
			]
		},
		pembayaran: {
			heading: {
				title: 'Metode Pembayaran',
				description: 'Platform Plaza Topup bekerja sama dengan berbagai platform yang memungkinkan<br>pembelian voucher game menggunakan platform pembayaran seperti:'
			},
			thumbnail: [
				{
					text: {
						title: 'Pulsa',
						description: '<p>Pembelian langsung menggunakan pulsa yang memungkinkan pembayaran yang mudah dan cepat</p>'
					},
					image: {
						src: '/assets/icons/icon phone.png',
						alt: 'Pulsa'
					}
				},
				{
					text: {
						title: 'E-money',
						description: '<p>Pembayaran sangat mudah dan cepat via e-money</p>'
					},
					image: {
						src: '/assets/icons/icon ewallet.png',
						alt: 'E-money'
					}
				},
				{
					text: {
						title: 'Transfer Bank',
						description: '<p>Pembayaran langsung menggunakan transfer bank via virtual account dari ATM terdekat maupun via mobile/internet banking</p>'
					},
					image: {
						src: '/assets/icons/icon bank.png',
						alt: 'Transfer Bank'
					}
				},
			]
		}
	}
	formFields: any = {
		register: [
			{
				label: 'Nama Lengkap',
				name: 'name',
				type: 'text',
				validation: [null, [Validators.required]],
				invalid: 'Nama wajib diisi'
			},
			{
				label: 'Nomor Handphone',
				name: 'phone',
				type: 'number',
				validation: [null, [Validators.required, Validators.minLength(10)]],
				invalid: 'Nomor terlalu pendek, minimal 10 karakter',
			},
			{
				label: 'Alamat Email',
				name: 'email',
				type: 'email',
				validation: [null, [Validators.required, Validators.email]],
				invalid: 'Email tidak valid'
			},
		]
	}
	constructor(
		private titleService: Title,
		private metaService: Meta,
		public helperService: HelperService,
		private fb: FormBuilder,
		private modalService: NgbModal,
		private http: HttpClient
	) {
		this.initForm()
	}
	ngOnInit() {
		this.titleMetaSettings();
	}
	titleMetaSettings() {
		this.titleService.setTitle(this.meta.title);
		this.metaService.updateTag({name: 'description', content: this.meta.description});
	};
	initForm() {
		let formTypes = Object.keys(this.forms)
		formTypes.forEach(type => {
			let fields = {}
			this.formFields[type].forEach(item => (
				fields[item.name] = item.validation || [null]
			))
			this.forms[type] = this.fb.group(fields);
		})
	}

	scroll(el: HTMLElement) {
		el.scrollIntoView();
	}

	voucherTypes = Option.vouchers;
	actionSubmit(contactForm: NgForm, modal) {
		if (contactForm.valid) {
			const form = contactForm.value;
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			this.http.post('https://api.plazatopup.com/api/contact-form',
				{ name: form.name, phone_number: form.phone, email: form.email },
				{ 'headers': headers }).subscribe(
				response => {
					const modalRef = this.modalService.open(modal, {backdrop: 'static',  keyboard: false, centered: true});
					setTimeout(() => {
						this.forms.register.setValidators(null);
						this.forms.register.reset();
						this.modalService.dismissAll();
					}, 3000)
				}
			);
		}
	}
}
