import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-modal-notification',
	templateUrl: './modal-notification.component.html',
	styleUrls: ['./modal-notification.component.scss']
})
export class ModalNotificationComponent implements OnInit {
	@Input() public passingData: any = [];
	@Output() public value = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	choiceAction(value) {
		this.value.emit(value);
	}
}
