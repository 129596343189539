
/* tslint:disable */
/**
 * This is generated by WaveCMSCore - do not edit directly!
 */

import {Injectable} from "@angular/core";
import {BaseApiService} from '../../BaseApiService';
import { UnauthorizedRedirectTo } from '../../../../wavecore/decorator/UnauthorizedRedirect';

@Injectable({
	providedIn: 'root',
})

export class Payment extends BaseApiService{
	async createInvoice(bodyParams: any): Promise<any> {
		bodyParams['merchantSite'] = 'KVCR';
		return await this.post('/invoice/create', bodyParams);
	}
	async getInvoice(invoiceNumber: string): Promise<any> {
		return await this.get('/invoice/details/'+invoiceNumber);
	}
	async doPayment(bodyParams: any): Promise<any> {
		return await this.post('/payment/do', bodyParams);
	}
	async getInquiryPln(type: string, bodyParams: any): Promise<any> {
		return await this.post(`/invoice/inquiry/pln/${type}`, bodyParams);
	}
	async confirmCryptoTransfer(bodyParams: any): Promise<any> {
		return await this.post(`/payment/crypto/confirm`, bodyParams);
	}
}
