import { Pipe, PipeTransform } from '@angular/core';
import {Constant} from '../util/Constant';
import {Country} from '../util/Country';

@Pipe({name: 'selector'})
export class SelectorPipe implements PipeTransform {

  constructor(private constant: Constant, private country: Country) {

  }

  transform(value: any, type: string) {
	if (type !== 'country') {
		if (this.constant[type].hasOwnProperty(value)) {
		return this.constant[type][value];
		}
	} else if (type === 'country') {
		if (this.country.list.hasOwnProperty(value)) {
		return this.country.list[value];
		}
	}

	return value;
  }
}
