import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'initial'})
export class InitialPipe implements PipeTransform {

  transform(value: string) {
	let initials: any = value;
	  initials = initials.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
	  if(initials) initials = initials.toUpperCase();

	return initials;
  }
}
