import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {Global} from '../util/Global';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
	constructor( public router: Router, public global: Global, public localstorage: LocalStorageService) {
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.localstorage.retrieve('access_token')) {
			this.router.navigate(['/']);
			return false;
		}
		return true;
	}
}
