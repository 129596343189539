import { Component, OnInit, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from './core/util/Global';
import { Meta } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import * as AOS from 'aos';
import { environment } from '../environments/environment';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{
	title = 'Hobitopup';

	constructor(
		private translate: TranslateService,
		public global: Global,
		private metaService: Meta,
		private router: Router,
		public gtmService: GoogleTagManagerService
	) {
		translate.setDefaultLang('en');
		translate.use('en');
		router.events.forEach(item => {
			if (item instanceof NavigationEnd) {
				this.global.isShadowHeader = item.url === '/quiz';

				const gtmTag = {
					event: 'page',
					pageName: item.url
				};
				this.gtmService.pushTag(gtmTag);
			}
		});
	}

	ngOnInit() {
        AOS.init();
		this.metaService.addTags(
			[
				{name: 'description', content: ''},
				{name: 'robots', content: environment.robots}
			]
		)
	}
}

// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'replaceUnderscore' })
export class ReplaceUnderscorePipe implements PipeTransform {
	transform(value: string): string {
		return value ? value.replace(/_/g, ' ') : value;
	}
}
