export class Constant {
	gender: any = {
		MALE: {name:'Laki-laki', value: 'MALE'},
		FEMALE: {name: 'Perempuan', value: 'FEMALE'}
	};

	defaultOption: any = {
		Y: 'Yes',
		N: 'No',
	};
}
