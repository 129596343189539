import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as Env from '../config/Env.json';
import { Constant } from '../util/Constant';
import { Global } from '../util/Global';

@Injectable()
export class BaseApiService {
	public redirectUrl: string;
	public redirectIfUnauthorized = false;
	constructor(
		public http: HttpClient,
		public global: Global,
		private constant: Constant,
		private router: Router,
		private _location: Location
	) {}

	public async get(url, useToken?) {
		if (useToken === undefined) { useToken = false; }
		return await this.getHttp('get', url, null, useToken);
	}

	public async post(url, body, useToken?) {
		if (useToken === undefined) { useToken = false; }

		return this.getHttp('post', url, body, useToken);
	}

	public async patch(url, body, useToken?) {
		if (useToken === undefined) { useToken = false; }

		return await this.getHttp('patch', url, body, useToken);
	}

	public async put(url, body, useToken?) {
		if (useToken === undefined) { useToken = false; }

		return await this.getHttp('put', url, body, useToken);
	}

	public async remove(url, useToken?) {
		if (useToken === undefined) { useToken = false; }
		return await this.getHttp('delete', url, null, useToken);
	}

	public async baseGet(url, useToken?) {
		if (useToken === undefined) { useToken = true; }

		return await this.getHttp('get', url, null, useToken);
	}

	public async basePost(url, body, useToken?) {
		if (useToken === undefined) { useToken = true; }

		return await this.getHttp('post', url, body, useToken);
	}

	public async basePut(url, body, useToken?) {
		if (useToken === undefined) { useToken = true; }

		return await this.getHttp('put', url, body, useToken);
	}

	public async basePatch(url, body, useToken?) {
		if (useToken === undefined) { useToken = true; }

		return await this.getHttp('patch', url, body, useToken);
	}

	public async baseDelete(url, body, useToken?) {
		if (useToken === undefined) { useToken = true; }

		return await this.getHttp('delete', url, body, useToken);
	}

	private async getHttp(type, url, body, useToken) {
		const header = this.getHeader();
		url = environment.baseUrl + url;
		if (this.global.token !== 'null' && useToken) {
			url = url + '?token=' + this.global.token;
		}

		if (useToken && body != null) {
			body.userID = 0;

			if (this.global.userID !== 'null') {
				body.userID = this.global.userID;
			}
		}

		let response = {};
		try{
			if (type === 'get') {
				response = await this.http.get(url, header).toPromise();
			} else if (type === 'delete'){
				response = await this.http.delete(url, header).toPromise();
			}
			else {
				response = await this.http[type](url, body, header).toPromise();
			}
		} catch (errorResponse){
			console.error(errorResponse);
			return errorResponse.error
		}

		if (this.redirectIfUnauthorized && this.redirectUrl && response['status'] === 'unauthorized') {
			this.router.navigate([this.redirectUrl]);
			return;
		}

		if(response['status'] === 'error'){
			return response;
		} else{
			return response['data'];
		}
	}

	protected getHeader() {
		let headers = new HttpHeaders();

		if(this.global.token !== null){
			headers = headers.append('Authorization', 'Bearer ' + this.global.token);
		}
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('XSRF-TOKEN', this.getCookieValue('XSRF-TOKEN'));
		return { headers };
	}

	protected getCookieValue(cookie) {
		const cookiePart = document.cookie.split(cookie + '=')[1];
		if (cookiePart) { return cookiePart.split(';')[0]; }
		return '';
	}

	private handleResponse(res: Response) {
		if (res.status === 200) {
			const body = res.text();

			return body;
		}
	}
}
