import { Pipe, PipeTransform } from '@angular/core';
import {Constant} from '../util/Constant';
import {Country} from '../util/Country';

@Pipe({name: 'readable'})
export class ReadablePipe implements PipeTransform {

  transform(value: string) {
    let stringValue = value.replace(/[^a-zA-Z ]/g, ' ');

    return stringValue;
  }
}
